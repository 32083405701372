<template>
  <div>
    <DepartmentList :canAdd="true" :canEdit="true" :canDelete="false" />
  </div>
</template>

<script>
// @ is an alias to /src
import DepartmentList from '@/components/OD/DepartmentList.vue';

export default {
  name: 'OfficialDepositoryDepartments',
  components: {
    DepartmentList,
  },
};
</script>
