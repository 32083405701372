<template>
    <v-container>
        <v-data-table
          :headers="headers"
          :items="departments"
          :items-per-page.sync="getSettings.itemsperpage"
          :server-items-length="getSettings.serverItemsLength"
          :footer-props="getSettings.footerProps"
          @update:page="handlePage"
          dense
          class="elevation-1"
        >
        <template v-slot:top="{ pagination, options, updateOptions }">
          <v-toolbar flat class="mr-0 pr-0">
            <v-spacer />
            <div>
              <v-data-footer
                :pagination="pagination"
                :options="options"
                @update:options="updateOptions"
                v-bind="getSettings.footerProps"
                items-per-page-text="$vuetify.dataTable.itemsPerPageText"
                :items-per-page.sync="getSettings.itemsperpage"
                :server-items-length="getSettings.serverItemsLength"
              />
            </div>
          </v-toolbar>
          <v-toolbar
            flat
          >
            <v-toolbar-title>Departments</v-toolbar-title>
            <v-divider
              class="mx-4" inset vertical
            ></v-divider>
            <v-col id="odDepartmentListDropDown"
              class="department-list-dropdown"
              cols="12" sm="3" md="2"
            >
              <v-select id="odDepartmentFySelect"
                class="od-department-fy-select"
                v-model="selectedfiscalYear"
                :items="getFiscalYears"
                @change="onFyChange"
                label="Fiscal Year"
                dense
                outlined
              ></v-select>
            </v-col>
            <v-divider
              class="mx-4" inset vertical
            ></v-divider>
            <CreateFiscalYear id="odDepartmentFyCreate"
              class="od-department-fy-create"
             :deptFiscalYear="selectedfiscalYear" />
            <v-spacer></v-spacer>
            <!-- New/Edit item dialog -->
            <v-dialog
              v-model="dialog"
              max-width="70vw"
              @keydown.enter="save"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-if="canAdd"
                  class="mx-2" color="primary"
                  fab icon
                  v-bind="attrs" v-on="on"
                >
                  <v-icon>
                    {{icons.mdiPlus}}
                  </v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="headline">{{ dialogTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-form ref="form">
                  <v-container>
                    <v-row>
                      <v-col
                        cols="12" sm="6" md="4"
                      >
                        <v-text-field id="odDepartmentListText"
                          class="od-department-list-text"
                          v-model="editedItem.dept"
                          label="Department"
                          type="number"
                          v-if="dialog" autofocus
                          :readonly = "!canEditDeptNumber"
                          :rules="[checkDuplicate, ...reqdSelectValidation]"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12" sm="6" md="4"
                      >
                        <v-text-field id="odDeptListDescriptionText"
                          class="od-deptlist-description-text"
                          v-model="editedItem.description"
                          label="Description"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12" sm="6" md="4"
                      >
                        <v-text-field id="odDeptListOfficerText"
                          class="od-deptlist-officer-text"
                          v-model="editedItem.officer"
                          label="Officer"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12" sm="6" md="4"
                      >
                        <v-text-field id="odDeptListTitleText"
                          class="od-deptlist-title-text"
                          v-model="editedItem.title"
                          label="Title"
                        ></v-text-field>
                      </v-col>
                      <v-col>
                        <v-select id="odDeptListBankNameSelect"
                          class="od-deptlist-bank-name-select"
                          v-model="editedItem.bankConfig"
                          :items="bankList"
                          item-value="id"
                          item-text="bankName"
                          label="Bank List"
                          dense
                          outlined
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        cols="2" sm="6" md="3"
                      >
                        <v-checkbox id="odDeptListTrackInterestChkBox"
                          class="od-deptlist-track-intrest-chkbox"
                          v-model="editedItem.trackInterest"
                          label="Track Interest"
                        ></v-checkbox>
                      </v-col>
                      <v-col
                        cols="4" sm="6" md="4"
                      >
                        <v-text-field id="odDeptListDailyAvgText"
                          class="od-deptlist-daily-avg-text"
                          v-model.number="editedItem.dailyAverageOverride"
                          label="Daily Average Override"
                          type="number"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="4" sm="6" md="4" v-if="isPostivePayBankEnabled">
                        <v-text-field id="odDeptListPostivePay"
                          class="od-deptlist-postive-pay"
                          v-model="editedItem.postivePayBankConfig"
                          label="Positive Pay Account Number"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                  </v-form>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn id="odDeptListCloseBtn"
                    class="od-deptlist-close-btn"
                    text color="blue darken-1"
                    @click="close"
                  >
                    Cancel
                  </v-btn>
                  <v-btn id="odDeptListSaveBtn"
                    class="od-deptlist-save-btn"
                    text color="blue darken-1"
                    @click="save"
                  >
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>

        <template v-slot:[`item`]="{ item, headers }">
          <tr :class="canEdit ? 'text-start clickable' : 'text-start'"
            @click="canEdit && editItem(item)"
          >
            <td
              v-for="header in headers" :key="header.value"
              :class="getClickableClass(header.value)"
            >
              <span
                v-if="header.value === 'deptBalance'"
                class="pointer"
              >
                {{amountFormat(item.deptBalance)}}
              </span>
              <span v-else>
                {{resolveSubProp(header.value, item)}}
              </span>
              <v-icon v-if="canDelete && header.value === 'actions'"
                small class="mr-2"
                @click.stop="deleteItem(item)"
              >
                {{icons.mdiDelete}}
              </v-icon>
            </td>
          </tr>
        </template>

        </v-data-table>
    </v-container>
</template>

<script>
import {
  mdiPlus,
  mdiDelete,
  mdiPencil,
} from '@mdi/js';
import {
  mapState,
  mapGetters,
  mapActions,
} from 'vuex';
import {
  destructureDate,
  toFiscalYear,
} from '../../util/shared/tmc-global';
import {
  amountFormat,
  amountToNumber,
  resolveSubProp,
} from '../../util/shared/vue-global';
import CreateFiscalYear from './CreateFiscalYear.vue';

const fields = {
  id: '',
  dept: '',
  description: '',
  officer: '',
  title: '',
  bankConfig: undefined,
  trackInterest: false,
  dailyAverage: 0.00,
  postivePayBankConfig: '',
};

const getHeaders = (canDelete) => {
  const headers = [
    { text: 'Department', value: 'dept' },
    { text: 'Description', value: 'description' },
    { text: 'Officer', value: 'officer' },
    { text: 'Title', value: 'title' },
    { text: 'Voucher', value: 'lastVoucher' },
    { text: 'Balance', value: 'deptBalance', align: 'right' },
  ];
  if (canDelete) {
    headers.push({ text: 'Actions', value: 'actions', sortable: false });
  }
  return headers;
};

export default {
  name: 'DepartmentList',
  props: {
    canAdd: {
      type: Boolean,
      default: false,
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
    canDelete: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    CreateFiscalYear,
  },
  data: () => ({
    icons: {
      mdiPlus,
      mdiDelete,
      mdiPencil,
    },
    criteria: {
      wildcard: undefined,
      skip: undefined,
      limit: undefined,
      fiscalYear: destructureDate().year,
    },
    // options: {},
    headers: [],
    dialog: false,
    editedIndex: -1,
    editedItem: { ...fields },
    defaultItem: { ...fields },
    selectedfiscalYear: destructureDate().year,
    odtransactions: [],
  }),
  computed: {
    canEditDeptNumber() {
      return this.isNewItem || (this.odtransactions || []).length === 0;
    },

    dialogTitle() {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item';
    },
    ...mapState({
      departments: (state) => state.OD.departments,
      bankList: (state) => state.SystemConfig.banksList,
      odStatsFY: (state) => state.OD.odStatsFY || [],
      odSettingItem: (state) => state.SystemConfig.odSettingItem,
    }),
    ...mapGetters('user', [
      'getSettings',
    ]),
    ...mapGetters([
      'todaysDate',
    ]),
    isPostivePayBankEnabled() {
      return this.odSettingItem && this.odSettingItem.enableODPostivePayBank;
    },
    getFiscalYears() {
      // filter value 0 from FY list
      return this.odStatsFY.map((x) => x.year).filter((fy) => fy !== 0);
    },
    isNewItem() {
      return this.editedIndex === -1;
    },
    reqdSelectValidation() {
      return [
        (value) => !!value || 'Required',
      ];
    },
  },
  created() {
    this.headers = getHeaders(this.canDelete);
    // first page default
    this.setCriteriaByPage(1);
    this.getCurrentFiscalYear();
    this.loadDepartments(this.criteria);
    this.loadConfig({ requiredScope: 'bank' });
    this.loadConfig({ requiredScope: 'odconfig' });
    this.loadFiscalYears();
  },
  methods: {
    resolveSubProp,
    ...mapActions('OD', [
      'loadDepartments',
      'loadFiscalYears',
      'searchTransactions',
    ]),
    ...mapActions('SystemConfig', [
      'loadConfig',
    ]),
    amountFormat,
    setCriteriaByPage(page) {
      const { itemsperpage } = this.getSettings;
      this.criteria.skip = (page - 1) * itemsperpage;
      this.criteria.limit = itemsperpage;
    },
    handlePage(page) {
      this.setCriteriaByPage(page);
      this.loadDepartments(this.criteria);
    },
    async editItem(item) {
      this.odtransactions.skip = 0;
      this.odtransactions.limit = 1;
      this.odtransactions = await this.searchTransactions({
        departments: [item.id],
      });
      this.editedIndex = this.departments.indexOf(item);
      this.editedItem = { ...item };
      this.dialog = true;
    },

    deleteItem(item) {
      if (window.confirm('Are you sure you want to delete this item?')) {
        this.$store.dispatch('OD/deleteDepartment', item);
      }
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
      });
    },
    editBeforeSave() {
      this.editedItem.dept = parseInt(this.editedItem.dept, 10);
      this.editedItem.dailyAverageOverride = amountToNumber(this.editedItem.dailyAverageOverride);
      this.editedItem.trackInterest = this.editedItem.trackInterest || false;
      this.editedItem.bankConfig = this.editedItem.bankConfig === undefined
        ? null : this.editedItem.bankConfig;
    },
    save() {
      const result = this.$refs.form.validate();
      if (result) {
        this.editBeforeSave();
        this.$store.dispatch('OD/upsDepartment', this.editedItem);
        this.close();
      }
    },
    onFyChange() {
      this.criteria.fiscalYear = this.selectedfiscalYear;
      this.loadDepartments(this.criteria);
    },
    getClickableClass(headerValue) {
      let classProperty = '';
      if (this.canEdit && headerValue === 'deptBalance') {
        classProperty = 'clickable text-aligh-right';
      } else if (this.canEdit) {
        classProperty = 'clickable';
      } else if (headerValue === 'deptBalance') {
        classProperty = 'text-aligh-right';
      }
      return classProperty;
    },
    getCurrentFiscalYear() {
      this.selectedfiscalYear = toFiscalYear(new Date(this.todaysDate));
      this.criteria.fiscalYear = toFiscalYear(new Date(this.todaysDate));
    },
    checkDuplicate() {
      let stateData = this.departments.map((x) => x);
      if (!this.isNewItem) {
        stateData = stateData.filter((item) => item.id !== this.editedItem.id);
      }
      const found = stateData.some((el) => Number(el.dept) === Number(this.editedItem.dept));
      if (found) {
        return `${this.editedItem.dept} already exist`;
      }
      return true;
    },
  },
};
</script>

<style lang="sass">
.v-data-table
  > .v-data-footer
    margin-right: 21px
    > .v-data-footer__select
      margin-right: 18px
    > .v-data-footer__icons-before
      margin-right: 9px
    > .v-data-footer__icons-after
      margin-left: 4px
.text-start .clickable:hover
  cursor: pointer
  font-weight: 600
.text-aligh-right
  text-align: right
.department-list-dropdown
  margin-top: 1.8vw
</style>
